<template>
  <v-card class="mb-10" flat>
    <v-card-text v-for="(item, ind) in items" :key="ind">
      <v-row dense>
        <v-col cols="12" sm="8">
          <h3 class="mb-2">{{ item.NOME }}</h3>
        </v-col>
      </v-row>
      <v-divider />
      <v-card flat>
        <v-card-title>Encomendas</v-card-title>
        <v-data-table
          :headers="headersPackages"
          :items="item.ENCOMENDAS"
          class="elevation-0"
        >
          <template v-slot:item.TOTAL="{ item }">
            {{ convertCurrent(item.TOTAL) }}
          </template>
          <template v-slot:item.DATA_HORA="{ item }">
            {{ convertDateTimeNew(item.DATA_HORA) }}
          </template>
          <template v-slot:item.COBRANCA="{ item }">
            {{ selectCobranca(item.COBRANCA) }}
          </template>

          <template v-slot:item.EXPEDIDOR="{ item }">
            {{ setExpedidorDesc(item.EXPEDIDOR) }}
          </template>
          <template v-slot:item.COD_BARRAS="{ item }">
            <MovimentacaoEncomendas :codBarras="item.COD_BARRAS" />
          </template>
        </v-data-table>
      </v-card>
      <v-divider />
      <v-card class="mt-3 mb-5" flat>
        <v-card-title>Passagens</v-card-title>
        <v-data-table
          :headers="headersTravels"
          :items="item.PASSAGENS"
          class="elevation-0"
        >
          <template v-slot:item.TOTAL="{ item }">
            {{ convertCurrent(item.TOTAL) }}
          </template>
          <template v-slot:item.DATA_HORA="{ item }">
            {{ convertDateTimeNew(item.DATA_HORA) }}
          </template>
          <template v-slot:item.COBRANCA="{ item }">
            {{ selectCobranca(item.COBRANCA) }}
          </template>
        </v-data-table>
      </v-card>
      <v-divider />
      <v-row dense class="mt-5 mb-5">
        <v-col cols="12" sm="2">
          <strong> TOTAL_ENC:</strong><br />
          <strong> {{ convertCurrent(item.ENCOMENDAS_VALOR_TOTAL) }}</strong>
        </v-col>
        <v-col cols="12" sm="2">
          <strong> COMISSÃO_ENC: </strong><br />
          <strong> {{ convertCurrent(item.ENCOMENDAS_COMISSAO) }} </strong>
        </v-col>
        <v-col cols="12" sm="2">
          <strong> TOTAL_PASS:</strong><br />
          <strong> {{ convertCurrent(item.PASSAGENS_VALOR_TOTAL) }} </strong>
        </v-col>
        <v-col cols="12" sm="2">
          <strong> COMISSÃO_PASS: </strong><br />
          <strong> {{ convertCurrent(item.PASSAGENS_COMISSAO) }} </strong>
        </v-col>
        <v-col cols="12" sm="2">
          <strong> TOTAL: </strong> <br />
          <strong>
            {{
              convertCurrent(
                parseFloat(
                  item.ENCOMENDAS_VALOR_TOTAL + item.PASSAGENS_VALOR_TOTAL
                ).toFixed(2)
              )
            }}
          </strong>
        </v-col>
        <v-col cols="12" sm="2">
          <strong> COMISSÃO: </strong><br />
          <strong>
            {{
              convertCurrent(
                parseFloat(
                  item.ENCOMENDAS_COMISSAO + item.PASSAGENS_COMISSAO
                ).toFixed(2)
              )
            }}
          </strong>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import mixin from "@/mixins";
import MovimentacaoEncomendas from "../../../../components/MovimentacaoEncomendas.vue";
export default {
  props: ["items", "headers-packages", "headers-travels"],
  mixins: [mixin],
  components: { MovimentacaoEncomendas },
};
</script>
