var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-10",attrs:{"flat":""}},_vm._l((_vm.items),function(item,ind){return _c('v-card-text',{key:ind},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('h3',{staticClass:"mb-2"},[_vm._v(_vm._s(item.NOME))])])],1),_c('v-divider'),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Encomendas")]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headersPackages,"items":item.ENCOMENDAS},scopedSlots:_vm._u([{key:"item.TOTAL",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertCurrent(item.TOTAL))+" ")]}},{key:"item.DATA_HORA",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDateTimeNew(item.DATA_HORA))+" ")]}},{key:"item.COBRANCA",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.selectCobranca(item.COBRANCA))+" ")]}},{key:"item.EXPEDIDOR",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setExpedidorDesc(item.EXPEDIDOR))+" ")]}},{key:"item.COD_BARRAS",fn:function(ref){
var item = ref.item;
return [_c('MovimentacaoEncomendas',{attrs:{"codBarras":item.COD_BARRAS}})]}}],null,true)})],1),_c('v-divider'),_c('v-card',{staticClass:"mt-3 mb-5",attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Passagens")]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headersTravels,"items":item.PASSAGENS},scopedSlots:_vm._u([{key:"item.TOTAL",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertCurrent(item.TOTAL))+" ")]}},{key:"item.DATA_HORA",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDateTimeNew(item.DATA_HORA))+" ")]}},{key:"item.COBRANCA",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.selectCobranca(item.COBRANCA))+" ")]}}],null,true)})],1),_c('v-divider'),_c('v-row',{staticClass:"mt-5 mb-5",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('strong',[_vm._v(" TOTAL_ENC:")]),_c('br'),_c('strong',[_vm._v(" "+_vm._s(_vm.convertCurrent(item.ENCOMENDAS_VALOR_TOTAL)))])]),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('strong',[_vm._v(" COMISSÃO_ENC: ")]),_c('br'),_c('strong',[_vm._v(" "+_vm._s(_vm.convertCurrent(item.ENCOMENDAS_COMISSAO))+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('strong',[_vm._v(" TOTAL_PASS:")]),_c('br'),_c('strong',[_vm._v(" "+_vm._s(_vm.convertCurrent(item.PASSAGENS_VALOR_TOTAL))+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('strong',[_vm._v(" COMISSÃO_PASS: ")]),_c('br'),_c('strong',[_vm._v(" "+_vm._s(_vm.convertCurrent(item.PASSAGENS_COMISSAO))+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('strong',[_vm._v(" TOTAL: ")]),_vm._v(" "),_c('br'),_c('strong',[_vm._v(" "+_vm._s(_vm.convertCurrent( parseFloat( item.ENCOMENDAS_VALOR_TOTAL + item.PASSAGENS_VALOR_TOTAL ).toFixed(2) ))+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('strong',[_vm._v(" COMISSÃO: ")]),_c('br'),_c('strong',[_vm._v(" "+_vm._s(_vm.convertCurrent( parseFloat( item.ENCOMENDAS_COMISSAO + item.PASSAGENS_COMISSAO ).toFixed(2) ))+" ")])])],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }